import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router/index.js'

const app = createApp(App);
//引入交互
import axios from 'axios'
//引入配置
import config from '../config/index.js'
//引入ViewUI
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'


import echarts from './utils/ChartsUtils';

//全局提示插件
import MessageUtils from './utils/MessageUtils.js'
import NoticeUtils from '@/utils/NoticeUtils.js'
import CommonUtil from '@/utils/CommonUtil.js'

import store from './store'
import 'vditor/src/assets/less/index.less'
app.config.globalProperties.config = config;
app.config.globalProperties.$state = store;
//为axios起一个别名
app.config.globalProperties.$http = axios;
app.config.globalProperties.$echarts = echarts
app.use(createRouter);
app.use(ViewUIPlus);
app.use(MessageUtils);
app.use(NoticeUtils);
app.use(CommonUtil);
app.use(store);
app.mount('#app');

const AUTH_WHITE_LIST = ['/login'];
createRouter.beforeEach(function (to, from, next) {
    if (AUTH_WHITE_LIST.indexOf(to.path) > -1) {
        next();
        return;
    }
    let token = localStorage.getItem('token')
    if (!token) {
        next({
            path: '/login'
        })
    } else {
        next();
    }
});


// 添加响应拦截器
axios.interceptors.response.use((data) => {
    ///axiox 异常过滤点
    if (data.data.code === -10) {
        ViewUIPlus.Message.error(data.data.message);
    }else if(data.data.code === -3){
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.setItem('isLogin', 0);
        ViewUIPlus.Message.error("账号在其他地方登录，请重新登录");
        createRouter.push("/login");
        return;
    }
    return data
}, (error) => {
    // 处理响应错误
    console.log(error)
    return Promise.reject(error)
})



axios.interceptors.request.use(
    config => {
        config.headers['token'] = localStorage.getItem('token') || '';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)
