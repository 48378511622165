// 引入路由对象
import {createRouter, createWebHashHistory} from 'vue-router'
import admin from './admin.js'
import material from './material.js'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', name: 'login', component: () => import('../pages/login/Login.vue')},
        {
            path: '/home',
            meta: {
                requiredAuth: true
            },
            component: () => import('../pages/home/Home.vue'),
            children: [
                {
                    path: 'welcome',
                    meta: {
                        requiredAuth: true
                    },
                    component: () => import('../pages/home/Welcome.vue'),
                },
                {
                    path: 'center',
                    meta: {
                        requiredAuth: true
                    },
                    component: () => import('../pages/home/Center.vue'),
                },
                {
                    path: 'userinfo',
                    meta: {
                        requiredAuth: true
                    },
                    component: () => import('../pages/home/Userinfo.vue'),
                },
                ...admin,
                ...material,
                {
                    path: 'error404',
                    name: 'error_404',
                    meta: {
                        title: '404-页面不存在'
                    },
                    component: () => import('../pages/exception/404.vue'),
                },

            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/home/error404'
        }
    ]
})
export default router