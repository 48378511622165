export default [
  {path: 'sysmenus', name: 'sysmenus', component: () => import('../pages/admin/menu/SysMenus.vue')},
  {path: 'appletsysmenu', name: 'appletsysmenu', component: () => import('../pages/admin/menu/AppletSysMenus.vue')},
  {path: 'sysapplys', name: 'sysapplys', component: () => import('../pages/admin/menu/SysApplys.vue')},
  {path: 'sysroles', name: 'sysroles', component: () => import('../pages/admin/role/SysRoles.vue')},
  {path: 'sysusers', name: 'sysusers', component: () => import('../pages/admin/user/SysUsers.vue')},
  {path: 'sysdepts', name: 'sysdepts', component: () => import('../pages/admin/dept/SysDepts.vue')},
  {path: 'sysconfig', name: 'sysconfig', component: () => import('../pages/admin/configure/SysConfig.vue')},
  {path: 'paramsmanager', name: 'paramsmanager', component: () => import('../pages/admin/params/ParamsManager.vue')},
]
