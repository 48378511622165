import { createStore } from 'vuex'

const store = createStore({
// state，mutations，actions和getters等选项
  state: {
    dbid:'',
    dbname:'',
    headerIndex:1,
  },
  mutations: {
    setDbid(state,data) {
      state.dbid = data;
    },
    setDbname(state,data){
      state.dbname = data;
    },
    setHeaderIndex(state,data){
      state.headerIndex = data;
    }
  },
  getters: {
    getDbid(state) {
      return state.dbid;
    },
    getDbName(state){
      return state.dbname;
    },
    getHeaderIndex(state){
      return state.headerIndex;
    }
  }
})

export default store
