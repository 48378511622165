'use strict'
// Template version: 1.2.3
// see http://vuejs-templates.github.io/webpack for documentation.

let serverUrl = '';
let homePage = ''
let imageUrl = '';
let uploadUrl = '';
let previewUrl = '';
let systemKey = '';


const devServerUrl = '/api';
const devHomePage = '/home/welcome'
const devImageUrl = 'http://test.lyhb.vip/image/';
const devUploadUrl = 'http://test.lyhb.vip/apply/api/upload/minio/upload/';
const devPreviewUrl = 'http://test.lyhb.vip/image/';
const devSystemKey = '1124342907273216000';


const testServerUrl = 'http://trace.lyhb.vip/material/';
const testHomePage = '/home/welcome'
const testImageUrl = 'http://test.lyhb.vip/image/';
const testUploadUrl = 'http://test.lyhb.vip/apply/api/upload/minio/upload/';
const testPreviewUrl = 'http://test.lyhb.vip/image/';
const testSystemKey = '1124342907273216000';


const prodServerUrl = 'https://trace.lyhb.vip/material/';
const prodHomePage = '/home/welcome'
const prodImageUrl = 'https://file.lyhb.vip/image/';
const prodUploadUrl = 'https://apply.lyhb.vip/apply/api/upload/minio/upload/';
const prodPreviewUrl = 'https://file.lyhb.vip/image/';
const prodSystemKey = '1124342907273216000';


if (process.env.NODE_ENV === 'development') {
    // 开发环境
    serverUrl = devServerUrl;
    homePage = devHomePage
    imageUrl = devImageUrl;
    uploadUrl = devUploadUrl;
    previewUrl = devPreviewUrl;
    systemKey = devSystemKey;
} else if (process.env.NODE_ENV === 'test') {
    // 测试环境
    serverUrl = testServerUrl;
    homePage = testHomePage
    imageUrl = testImageUrl;
    uploadUrl = testUploadUrl;
    previewUrl = testPreviewUrl;
    systemKey = testSystemKey;
} else {
    // 生产环境
    serverUrl = prodServerUrl;
    homePage = prodHomePage
    imageUrl = prodImageUrl;
    uploadUrl = prodUploadUrl;
    previewUrl = prodPreviewUrl;
    systemKey = prodSystemKey;
}


module.exports = {
    serverUrl: serverUrl,
    homePage: homePage,
    imageUrl: imageUrl,
    uploadUrl: uploadUrl,
    previewUrl: previewUrl,
    systemKey: systemKey

}
