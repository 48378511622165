export default {
  install(app, options) {
    app.config.globalProperties.MessageUtils = function (response) {
      if (response.data.code === '1') {
        this.$Message.success({
          content: response.data.message,
          duration: 2
        });
      } else if (response.data.code === '0') {
        this.$Message.warning({
          content: response.data.message,
          duration: 2
        });
      } else {
        this.$Message.error({
          content: response.data.message,
          duration: 2
        });
      }
    }
  }
}
