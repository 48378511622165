export default [
    {path: 'category', name: 'category', component: () => import('../pages/material/Category.vue')},
    {path: 'warehouse', name: 'warehouse', component: () => import('../pages/material/Warehouse.vue')},
    {path: 'outmark', name: 'outmark', component: () => import('../pages/material/Outmark.vue')},
    {path: 'batchOutbound', name: 'batchOutbound', component: () => import('../pages/material/BatchOutbound.vue')},
    {path: 'productOutbound', name: 'productOutbound', component: () => import('../pages/material/ProductOutbound.vue')},
    {path: 'stock', name: 'stock', component: () => import('../pages/material/Stock.vue')},
    {path: 'picking', name: 'picking', component: () => import('../pages/material/Picking.vue')},
    {path: 'storageList', name: 'storageList', component: () => import('../pages/material/StorageList.vue')},
    {path: 'storageListDept', name: 'storageListDept', component: () => import('../pages/material/StorageListDept.vue')},
    {path: 'productRel', name: 'productRel', component: () => import('../pages/material/ProductRel.vue')},
    {path: 'snapshot', name: 'snapshot', component: () => import('../pages/material/Snapshot.vue')},
    {path: 'customInfo', name: 'customInfo', component: () => import('../pages/material/CustomInfo.vue')},
    {path: 'projectInfo', name: 'projectInfo', component: () => import('../pages/material/ProjectInfo.vue')},
]
