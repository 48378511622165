export default {
  install(app, options) {
    app.config.globalProperties.NoticeUtils = function (response) {
      if (response.data.code === '1') {
        this.$Notice.success({
          title: response.data.message
        });
      } else if (response.data.code === '0') {
        this.$Notice.warning({
          title: response.data.message
        });
      } else {
        this.$Notice.error({
          title: response.data.message
        });
      }
    }
  }
}
