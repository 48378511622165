function getImagePreview(fileId) {
    return this.config.previewUrl + fileId + '_view.jpg';
}

function handleImageClick(fileId) {
    this.imageSrc = this.config.previewUrl + fileId + '_view.jpg';
    this.imageMode = true;

}



export default {
    install(app, options) {
        app.config.globalProperties.getImagePreview = getImagePreview;
        app.config.globalProperties.handleImageClick = handleImageClick;
    },
}
